import React, { useEffect, useState } from 'react';
import './Invoice.css';
import logo from "../../Images/company-logo.png";
import { useParams } from 'react-router-dom';

const Invoice = () => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}invoice/${id}`, {
          method: 'GET',
        });

        if (response.ok) {
          const data = await response.json();
          setInvoiceData(data.data);
        } else {
          console.error('Error fetching invoice:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching invoice:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoiceData();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!invoiceData) {
    return <div>No invoice data found.</div>;
  }

  return (
    <div className="invoice-container" id="invoice">
      <header className="invoice-header">
        <img src={logo} alt="Company Logo" className="logo" />
        <div className="text">
          <h2>PROFORMA INVOICE</h2>
        </div>
      </header>
      <div className="company-detail">
        <p>Corporate Identity Number: U265767SDB87PCTC714623</p>
        <p>GST Registration Number: 36AAFCL4792K1ZG</p>
        <p>PAN No.: AAFCJ4792K</p>
      </div>

      <div className="order-info">
        <p><span>Invoice No: </span> <span className="invoice-no">{invoiceData.invoicenumber}</span></p>
        <p><span>Date: </span><span className="invoice-date">{new Date(invoiceData.date).toLocaleDateString()}</span></p>
      </div>

      <div className="billing-info">
        <div className="left">
          <p><strong>Corporate Office:</strong></p>
          <p>LABYRINTH GLOBAL SOLUTIONS INDIA PRIVATE LIMITED</p>
          <p>H.No. 16-2-684/1, Chanchalguda, Malakpet</p>
          <p>Charminar, Hyderabad, Telangana, 500002</p>
          <p>India</p>
        </div>
        <div className="right">
          <p><strong>Billing Office:</strong></p>
          <p>LABYRINTH GLOBAL SOLUTIONS INDIA PRIVATE LIMITED</p>
          <p>H.No. 16-2-684/1, Chanchalguda, Malakpet</p>
          <p>Charminar, Hyderabad, Telangana, 500002</p>
          <p>India</p>
        </div>
      </div>

      <div className="address-info">
        <div>
          <p><strong>Vendor Code:</strong></p>
          <p>Bill & Ship to Address:</p>
          <p>Accounts Payable</p>
         
          <p>{invoiceData.venderAddress}</p>
        </div>
        <div>
          <p>GSTIN: {invoiceData.venderGstNumber}</p>
          <p><span>Customer Name:</span> <span> {invoiceData.venderName}</span></p>
        </div>
      </div>

      <table className="invoice-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Qty</th>
            <th>Product Code</th>
            <th>Product Description</th>
            <th>Amount (Rs.)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>{invoiceData.qty}</td>
            <td>{invoiceData.productcode}</td>
            <td>{invoiceData.description}</td>
            <td>{parseFloat(invoiceData.totalAmount).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>

      <div className="total">
        <p><strong>Total Invoice Value:</strong> {parseFloat(invoiceData.totalAmount).toFixed(2)}</p>
      </div>

      <footer className="invoice-footer">
        <p>Satyabrata Das</p>
        <h4>(Authorised Signatory)</h4>
      </footer>

      <div className="bank-details">
        <p><strong>Bank Details:</strong></p>
        <p>Bank Name: {invoiceData.bankName}</p>
        <p>Account No.: {invoiceData.bankAccountNumber}</p>
        <p>Account Type: {invoiceData.accountType}</p>
        <p>IFSC/NEFT Code: {invoiceData.bankIfscCode}</p>
      </div>
    </div>
  );
};

export default Invoice;
