import React from 'react';

import './PurchaseOrder.css';
import logo from "../../Images/company-logo.png";
import PurchaseOrders from "./PurchaseOrders";

const PurchaseOrder = () => {




  return (
    <>
      <div id="purchase-order">
        <div className="po-container">
          <header className="header">
            <img src={logo} alt="Company Logo" className="logo" />
            <div className="header-text">
              <h1>LABYRINTH GLOBAL SOLUTIONS INDIA PRIVATE LIMITED</h1>
            </div>
          </header>

          <div className="po-info">
            <table className="po-info-table">
              <tbody>
                <tr>
                  <td className="info-block">
                    <h3>Detail of Receiver (Billed to):</h3>
                    <p>LABYRINTH GLOBAL SOLUTIONS INDIA PRIVATE LIMITED</p>
                    <p>H.No. 16-2-684/1, Chanchalguda, Malakpet</p>
                    <p>Charminar, Hyderabad, Telangana, 500002</p>
                    <p>State Code: 36 - TELANGANA</p>
                    <p>GSTIN: 36AAFCL4792K1ZG</p>
                    <p>CIN: U265767SDB87PCTC714623</p>
                  </td>
                  <td className="info-block">
                    <h3>Deliver to:</h3>
                    <p>LABYRINTH GLOBAL SOLUTIONS INDIA PRIVATE LIMITED</p>
                    <p>H.No. 16-2-684/1, Chanchalguda, Malakpet</p>
                    <p>Charminar, Hyderabad, Telangana, 500002</p>
                    <p>State Code: 36 - TELANGANA</p>
                    <p>GSTIN: 36AAFCL4792K1ZG</p>
                    <p>CIN: U265767SDB87PCTC714623</p>
                  </td>
                  <td className="info-block">
                    <div className="info-line"><span>PO No:</span> <span>9860029124</span></div>
                    <div className="info-line"><span>PO Date:</span> <span>18.02.2024</span></div>
                    <div className="info-line"><span>AMD No:</span> <span>8</span></div>
                    <div className="info-line"><span>AMD Date:</span> <span>08.03.2004</span></div>
                    <div className="info-line"><span>W.E.F:</span> <span>01.01.2000</span></div>
                    <div className="info-line"><span>W.E.T:</span> <span>18.12.2024</span></div>
                    <div className="info-line"><span>CURRENCY:</span> <span>INR</span></div>
                    <div className="info-line"><span>GTN NO & DATE:</span> <span>01.01.0000</span></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="vendor-container">
            <div className="vendor-details1">
              <h3>Vendor Name & Address</h3>
              <p>LABYRINTH GLOBAL SOLUTIONS INDIA PVT. LTD.</p>
              <p>H.No. 16-2-684/1, Chanchalguda, Malakpet , Charminar, Hyderabad, Telangana, 500002</p>
              <p>Telangana, TELANGANA, India</p>
            </div>
            <div className="vendor-details2">
              <p className='info-line'> <span>VendorCode:</span>  <span> 453453</span></p>
              <p className='info-line'> <span>GSTIN:</span>  <span> 36AAFCL4792K1ZG</span></p>
              <p className='info-line'> <span>CIN:</span>  <span> U265767SDB87PCTC714623</span></p>
            </div>
          </div>

          <div className="po-table">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Material Code</th>
                  <th>Description</th>
                  <th>HSN Code</th>
                  <th>UOM</th>
                  <th>Qty</th>
                  <th>Rate Per Unit</th>
                  <th>Disc %</th>
                  <th>GST</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Plastico</td>
                  <td>892123</td>
                  <td>10%</td>
                  <td>2</td>
                  <td>100.00</td>
                  <td>10.00</td>
                  <td>18%</td>
                  <td>236.00</td>
                  <td>10000</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Metal</td>
                  <td>892124</td>
                  <td>5%</td>
                  <td>5</td>
                  <td>200.00</td>
                  <td>5.00</td>
                  <td>18%</td>
                  <td>1,190.00</td>
                  <td>2098</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="terms">
            <h3>Terms & Conditions:</h3>
            <p>1. Immediate due date</p>
            <p>2. Payment Terms: As soon as possible</p>
          </div>

          <div className="terms-conditions">
            <table>
              <tbody>
                <tr>
                  <td>1. PAYMENT TERMS</td>
                  <td>Immediate due</td>
                  <td className="amount-label">SUB TOTAL</td>
                  <td className="amount-value">00000.00</td>
                </tr>
                <tr>
                  <td>2. PAYMENT DETAIL</td>
                  <td></td>
                  <td className="amount-label">GST AMOUNT</td>
                  <td className="amount-value">0000.00</td>
                </tr>
                <tr>
                  <td>3. FREIGHT COST</td>
                  <td>dap</td>
                  <td className="amount-label">DISCOUNT</td>
                  <td className="amount-value">0.00</td>
                </tr>
                <tr>
                  <td>4. TRANSIT INSURANCE</td>
                  <td></td>
                  <td className="amount-label">PACKING CHARGES</td>
                  <td className="amount-value">0.00</td>
                </tr>
                <tr>
                  <td>5. PACKING FORWARDING</td>
                  <td></td>
                  <td className="amount-label">FREIGHT CHARGES</td>
                  <td className="amount-value">0.00</td>
                </tr>
                <tr>
                  <td>6. MODE OF DISPATCH</td>
                  <td></td>
                  <td className="amount-label">OTHER CHARGES</td>
                  <td className="amount-value">0.00</td>
                </tr>
                <tr>
                  <td>7. DELIVERY</td>
                  <td></td>
                  <td className="amount-label">VAT AMOUNT</td>
                  <td className="amount-value">0.00</td>
                </tr>
                <tr>
                  <td colSpan="2"></td>
                  <td className="amount-label">GRAND TOTAL</td>
                  <td className="amount-value">00000.00</td>
                </tr>
              </tbody>
            </table>
            <div className="additional-info">
              <p>NOTE :</p>
              <ul>
                <li>1. Delivery schedule will be released separately</li>
                <li>2. Material would be dispatch accordance of monthly schedule</li>
              </ul>
            </div>
            <div className="remarks">
              <p>REMARKS</p>
            </div>
            <div className="grand-total">
              <p>GRAND TOTAL (IN WORDS):</p>
            </div>
            <footer className="footer">
              <p>for LABYRINTH GLOBAL SOLUTIONS INDIA PRIVATE LIMITED</p>
              <h4>(Authorised Signatory)</h4>
            </footer>
          </div>
        </div>
      </div>
      <div id="purchase-orders" className="page-break">
        <PurchaseOrders />
      </div>
      
    </>
  );
}

export default PurchaseOrder;