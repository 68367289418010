import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./ExEmployees.css";

const ExEmployees = () => {
  const [exEmployees, setExEmployees] = useState([]);

  const { id } = useParams();
  
  useEffect(() => {
    const fetchExEmployees = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}employee/inactive`);
        const data = await response.json();
        console.log("API Response:", data); // Log the API response
        // Update to reflect the correct path in the response
        setExEmployees(data.data.employees || []); // Use an empty array if employees is undefined
      } catch (error) {
        console.error("Error fetching ex-employees:", error);
      }
    };

    fetchExEmployees();
  }, []);

  return (
    <div className="ex-employees-container">
      <h2>Ex-Employees</h2>
      <Link to="/EmployeeForm" className="team-button">
        Add Ex-Employee
      </Link>
      <table className="team-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Employee Id</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Email</th>
           
            <th>Create Payslip</th>
          </tr>
        </thead>
        <tbody>
          {exEmployees.map((employee, index) => (
            <tr key={employee.id}>
              <td>{index + 1}</td>
              <td>{employee.employeeId}</td>
              <td>{employee.name}</td>
              <td>{employee.designation}</td>
              <td>{employee.email}</td>
              {/* <td>
                <Link to={`/edit/${employee.id}`}>
                  <button className="action-button edit">
                    <i className="fas fa-edit"></i>
                  </button>
                </Link>
                <Link to={`/view/${employee.id}`}>
                  <button className="action-button view">
                    <i className="fas fa-eye"></i>
                  </button>
                </Link>
              </td> */}
              <td>
                <Link to={`/payslip/${employee.id}`}>
                  <button className="payslip-button">
                    Create Payslip
                  </button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExEmployees;
