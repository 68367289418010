import React, { useState } from "react";
import "./EmployeeForm.css";
import { useNavigate } from "react-router-dom";
import Toast from "../../utils/toast";

const PayslipForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    employeeId: "",
    name: "",
    email: "",
    phoneNumber: "",
    designation: "",
    location: "",
    pan: "",
    gender: "Male",
    bankAccount: "",
    salary: "",
    dateOfJoining: "",
    pfAccount: "",
    uan: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}employee/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      setLoading(false);
      if (response.ok) {
        const data = await response.json();
        Toast.fire({ icon: "success", title: "Employee successfully added!" });
        navigate("/users");
      } else {
        const data = await response.json();
        Toast.fire({ icon: "error", title: data.data });
      }
    } catch (error) {
      setLoading(false);
      Toast.fire({ icon: "error", title: "An error occurred!" });
    }
  };

  return (
    <div className="payslip-form-container">
      {loading && <div className="loader">Loading...</div>}
      <form onSubmit={handleSubmit} className="payslip-form">
        <h2 style={{ fontWeight: "bold" }}>Employee Information</h2>
        <div className="form-row">
          <label>Employee ID</label>
          <input
            required
            type="text"
            name="employeeId"
            value={formData.employeeId}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Name</label>
          <input
            required
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Email</label>
          <input
            required
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Location</label>
          <input
            required
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Phone Number</label>
          <input
            required
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Salary</label>
          <input
            required
            type="text"
            name="salary"
            value={formData.salary}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Designation</label>
          <input
            required
            type="text"
            name="designation"
            value={formData.designation}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>PAN</label>
          <input
            required
            type="text"
            name="pan"
            value={formData.pan}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Gender</label>
          <select
            required
            name="gender"
            value={formData.gender}
            onChange={handleChange}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className="form-row">
          <label>Bank A/C</label>
          <input
            required
            type="text"
            name="bankAccount"
            value={formData.bankAccount}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Date Of Joining</label>
          <input
            required
            type="date"
            name="dateOfJoining"
            value={formData.dateOfJoining}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>PF Account</label>
          <input
            required
            type="text"
            name="pfAccount"
            value={formData.pfAccount}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>UAN</label>
          <input
            required
            type="text"
            name="uan"
            value={formData.uan}
            onChange={handleChange}
          />
        </div>
        <button className="payslip-form-button" type="submit">
          Create
        </button>
      </form>
    </div>
  );
};

export default PayslipForm;
